
$(document).ready(function () {
    var currentSiteId = $('#currentSiteIdValue').val();
    var cookiesUserAcceptanceKey = 'cookiesUserAcceptance_' + currentSiteId;
    var cookiesUserAcceptance = localStorage.getItem(cookiesUserAcceptanceKey);
    var cookiesPopUpElement = $('.cookies-policy-container');
    if (!cookiesUserAcceptance) {
        cookiesPopUpElement.removeClass('d-none');
    }
    $('.cookies-close-popup').on('click', function () {
        cookiesPopUpElement.addClass('d-none');
        localStorage.setItem(cookiesUserAcceptanceKey, true);
    });
});